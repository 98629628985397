@import-normalize;

html,
body {
  --main-black: #222222;
  --main-white: #eeeeee;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Droid Sans', 'Helvetica Neue', Helvetica, Arial, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Microsoft YaHei Light',
    'Microsoft YaHei';
  font-size: 16px;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-black);
  overflow: hidden;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#root {
  min-width: 100vw;
  min-height: 100vh;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

header {
  width: 5em;
  height: 1.6em;
  color: var(--main-black);
  font-size: 1.2em;
  line-height: 1.2em;
  border: 1px solid var(--main-black);
  border-top: none;
  background: transparent;
  position: fixed;
  right: 0;
  opacity: 0.8;
  z-index: 100;
}

header div {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

header div span {
  cursor: pointer;
}

.shadow {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: var(--main-black);
  transition: opacity 0.2s ease-in-out;
}

.shadow.hide {
  opacity: 0;
}

.shadow.show {
  opacity: 0.2;
}

.container {
  position: fixed;
  width: 100vw;
  height: 50vh;
  color: white;
  background: var(--main-black);
  box-shadow: 0px 0px 2px var(--main-black);
  transition: bottom 0.5s ease-in-out;
}

.container.hide {
  bottom: -60vh;
  animation: arc 0.7s ease-in-out 1 forwards;
}

.container.show {
  bottom: 0;
  animation: back-arc 0.7s ease-in-out 1 forwards;
}

.container::after {
  content: '';
  width: 100vw;
  height: 100vh;
  background: var(--main-black);
  transition: bottom 0.3s ease-in-out;
  position: absolute;
  top: 25vh;
}

.container div {
  width: 80vw;
  margin: 0 10vw;
  transform: translateY(-5em);
  transition: opacity 0.3s 0.7s ease-in-out;
  position: absolute;
  top: 0;
  z-index: 100;
}

.container div h1 {
  margin: 0.5em 0;
  color: #ffffff;
  font-size: 3.5em;
  line-height: 1.5em;
  text-shadow: 4px 4px 4px var(--main-black);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.container div pre {
  height: calc(50vh - 7em);
  margin: 0;
  line-height: normal;
  text-shadow: 2px 4px 2px black;
  white-space: pre-wrap;
  overflow-y: scroll;
}

.container div pre p {
  margin: 0;
}

.container.hide div {
  opacity: 0;
}

.container.show div {
  opacity: 1;
}

.container *::selection {
  color: var(--main-black);
  background: var(--main-white);
  text-shadow: none;
}

@keyframes arc {
  0% {
    border-radius: 100% / 0%;
  }
  100% {
    border-radius: 100% / 10%;
  }
}

@keyframes back-arc {
  0% {
    border-radius: 100% / 100%;
  }
  100% {
    border-radius: 100% / 0%;
  }
}

@media screen and (max-width: 960px) {
  html,
  body {
    font-size: 14px;
  }

  .container div {
    transform: translateY(-4.2em);
  }

  .container div h1 {
    font-size: 3em;
  }

  .container div pre {
    height: calc(50vh - 6.5em);
  }
}

@media screen and (max-width: 600px) {
  header {
    right: unset;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
